.dynamic-content-block {
    position: relative;
    border-radius: 24px;
    background: var(--ion-color-app-outline);
    margin: 20px 0;

    &__sidebar {
        position: absolute;

        width: 12px;
        height: 100%;

        border-radius: 24px 0 0 24px;

        background-color: var(--ion-color-charly);
    }

    &__title {
        padding: 20px 16px 4px 24px;
        margin: unset;

        color: var(--ion-color-jack);
    }

    &__content {
        padding: 4px 16px 20px 24px;

        p {
            margin: unset;
        }

        color: var(--ion-color-paragraph-color);
    }
}
