@use 'mixins/media-query.mixin';

button.zz-button {
    font-family: 'Helvetica Now Text', serif;
    background-color: transparent;
    color: var(--ion-color-paragraph-color);

    // increments of 2 but missing a lot of sizes
    font-size: 17px;
    line-height: 26px;

    &.s {
        font-size: 15px;
        line-height: 23px;
    }

    &.l {
        font-size: 19px;
        line-height: 29px;
    }

    &.xl {
        font-size: 25px;
        line-height: 38px;
    }

    &.xxl {
        font-size: 33px;
        line-height: 50px;
    }

    &.xxxl {
        font-size: 39px;
        line-height: 59px;
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include media-query.if-small-phone {
            font-size: 15px;
            line-height: 23px;
        }

        @include media-query.if-small-phone {
            &.s {
                font-size: 13px;
                line-height: 20px;
            }
        }

        @include media-query.if-small-phone {
            &.l {
                font-size: 17px;
                line-height: 26px;
            }
        }

        @include media-query.if-small-phone {
            &.xl {
                font-size: 23px;
                line-height: 35px;
            }
        }

        @include media-query.if-small-phone {
            &.xxl {
                font-size: 31px;
                line-height: 47px;
            }
        }

        @include media-query.if-small-phone {
            &.xxxl {
                font-size: 37px;
                line-height: 56px;
            }
        }
    }
}
