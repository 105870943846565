/** Primary **/
.font-max {
    color: var(--ion-color-max) !important;
}

.font-harley {
    color: var(--ion-color-harley) !important;
}

.font-alvin {
    color: var(--ion-color-alvin) !important;
}

.font-buddy {
    color: var(--ion-color-buddy) !important;
}

/** Secondary light **/
.font-luna {
    color: var(--ion-color-luna) !important;
}

.font-lola {
    color: var(--ion-color-lola) !important;
}

.font-maggie {
    color: var(--ion-color-maggie) !important;
}

.font-bailey {
    color: var(--ion-color-bailey) !important;
}

/** Secondary bright **/
.font-bella {
    color: var(--ion-color-bella) !important;
}

.font-charly {
    color: var(--ion-color-charly) !important;
}

.font-sophie {
    color: var(--ion-color-sophie) !important;
}

.font-oliver {
    color: var(--ion-color-oliver) !important;
}

/** Additional App **/
.font-disabled-color {
    color: var(--ion-color-disabled) !important;
}

.font-app-background {
    color: var(--ion-color-app-background) !important;
}

.font-app-outline {
    color: var(--ion-color-app-outline) !important;
}

/** Text Primary **/
.font-jack {
    color: var(--ion-color-jack) !important;
}

.font-snowy {
    color: var(--ion-color-snowy) !important;
}

/** Text Secondary **/
.font-semi-jack {
    color: var(--ion-color-semi-jack) !important;
}

.font-paragraph-color {
    color: var(--ion-color-paragraph-color) !important;
}

.font-disabled-text-color {
    color: var(--ion-color-disabled-text) !important;
}

/** Not in UI kit **/
.font-barney {
    color: var(--ion-color-barney) !important;
}
