.image-box {
    display: flex;
    width: 100%;
    height: 200px;
    align-items: center;
    justify-content: center;
    padding: 40px 0 24px;
}

.image-no-max-width {
    max-width: initial;
}

.image-self {
    max-width: 100%;
    max-height: 100%;
}
