:root {
    /** Custom colors (primary) **/
    --ion-color-max: #fc5756;
    --ion-color-max-rgb: 252, 87, 86;
    --ion-color-max-contrast: #ffffff;
    --ion-color-max-contrast-rgb: 255, 255, 255;
    --ion-color-max-shade: #de4d4c;
    --ion-color-max-tint: #fc6867;
    --ion-color-max-very-light: 252, 87, 86, 0.1;
    --ion-color-max-secondary: var(--ion-color-bella);
    --ion-color-max-secondary-light: var(--ion-color-luna);

    --ion-color-harley: #13355d;
    --ion-color-harley-rgb: 19, 53, 93;
    --ion-color-harley-contrast: #ffffff;
    --ion-color-harley-contrast-rgb: 255, 255, 255;
    --ion-color-harley-shade: #112f52;
    --ion-color-harley-tint: #2b496d;
    --ion-color-harley-secondary: var(--ion-color-charly);
    --ion-color-harley-secondary-light: var(--ion-color-lola);

    --ion-color-alvin: #073f38;
    --ion-color-alvin-rgb: 7, 63, 56;
    --ion-color-alvin-contrast: #ffffff;
    --ion-color-alvin-contrast-rgb: 255, 255, 255;
    --ion-color-alvin-shade: #063731;
    --ion-color-alvin-tint: #20524c;
    --ion-color-alvin-secondary: var(--ion-color-sophie);
    --ion-color-alvin-secondary-light: var(--ion-color-maggie);

    --ion-color-buddy: #b57639;
    --ion-color-buddy-rgb: 181, 118, 57;
    --ion-color-buddy-contrast: #ffffff;
    --ion-color-buddy-contrast-rgb: 255, 255, 255;
    --ion-color-buddy-shade: #9f6832;
    --ion-color-buddy-tint: #bc844d;
    --ion-color-buddy-secondary: var(--ion-color-oliver);
    --ion-color-buddy-secondary-light: var(--ion-color-bailey);

    /** Custom colors (secondary light) **/
    --ion-color-luna: #f1cfcb;
    --ion-color-luna-rgb: 241, 207, 203;
    --ion-color-luna-contrast: #000000;
    --ion-color-luna-contrast-rgb: 0, 0, 0;
    --ion-color-luna-shade: #d4b6b3;
    --ion-color-luna-tint: #f2d4d0;

    --ion-color-lola: #baedf6;
    --ion-color-lola-rgb: 186, 237, 246;
    --ion-color-lola-contrast: #000000;
    --ion-color-lola-contrast-rgb: 0, 0, 0;
    --ion-color-lola-shade: #a4d1d8;
    --ion-color-lola-tint: #c1eff7;

    --ion-color-maggie: #cfdbd2;
    --ion-color-maggie-rgb: 207, 219, 210;
    --ion-color-maggie-contrast: #000000;
    --ion-color-maggie-contrast-rgb: 0, 0, 0;
    --ion-color-maggie-shade: #b6c1b9;
    --ion-color-maggie-tint: #d4dfd7;

    --ion-color-bailey: #f6e7ce;
    --ion-color-bailey-rgb: 246, 231, 206;
    --ion-color-bailey-contrast: #000000;
    --ion-color-bailey-contrast-rgb: 0, 0, 0;
    --ion-color-bailey-shade: #d8cbb5;
    --ion-color-bailey-tint: #f7e9d3;

    /** Custom colors (secondary bright) **/
    --ion-color-bella: #e49c81;
    --ion-color-bella-rgb: 228, 156, 129;
    --ion-color-bella-contrast: #000000;
    --ion-color-bella-contrast-rgb: 0, 0, 0;
    --ion-color-bella-shade: #c98972;
    --ion-color-bella-tint: #e7a68e;

    --ion-color-charly: #196bc1;
    --ion-color-charly-rgb: 25, 107, 193;
    --ion-color-charly-contrast: #ffffff;
    --ion-color-charly-contrast-rgb: 255, 255, 255;
    --ion-color-charly-shade: #165eaa;
    --ion-color-charly-tint: #307ac7;
    --ion-color-charly-secondary: var(--ion-color-harley);
    --ion-color-charly-secondary-light: var(--ion-color-lola);

    --ion-color-sophie: #02704b;
    --ion-color-sophie-rgb: 2, 112, 75;
    --ion-color-sophie-contrast: #ffffff;
    --ion-color-sophie-contrast-rgb: 255, 255, 255;
    --ion-color-sophie-shade: #026342;
    --ion-color-sophie-tint: #1b7e5d;
    --ion-color-sophie-secondary: var(--ion-color-alvin);
    --ion-color-sophie-secondary-light: var(--ion-color-maggie);

    --ion-color-oliver: #faca2a;
    --ion-color-oliver-rgb: 250, 202, 42;
    --ion-color-oliver-contrast: #000000;
    --ion-color-oliver-contrast-rgb: 0, 0, 0;
    --ion-color-oliver-shade: #dcb225;
    --ion-color-oliver-tint: #fbcf3f;

    /** Custom colors (additional app) **/
    --ion-color-disabled: #f2f2f2;
    --ion-color-disabled-rgb: 242, 242, 242;
    --ion-color-disabled-contrast: #000000;
    --ion-color-disabled-contrast-rgb: 0, 0, 0;
    --ion-color-disabled-shade: #d5d5d5;
    --ion-color-disabled-tint: #f3f3f3;

    --ion-color-app-background: #fcfbfa;
    --ion-color-app-background-rgb: 252, 251, 250;
    --ion-color-app-background-contrast: #000000;
    --ion-color-app-background-contrast-rgb: 0, 0, 0;
    --ion-color-app-background-shade: #dedddc;
    --ion-color-app-background-tint: #fcfbfb;

    --ion-color-app-background-max: #fcfbfa;
    --ion-color-app-background-max-rgb: 252, 251, 250;
    --ion-color-app-background-max-contrast: #fc5756;
    --ion-color-app-background-max-contrast-rgb: 252, 87, 86;
    --ion-color-app-background-max-shade: #dedddc;
    --ion-color-app-background-max-tint: #fcfbfb;

    --ion-color-app-outline: #f7f3ef;
    --ion-color-app-outline-rgb: 247, 243, 239;
    --ion-color-app-outline-contrast: #000000;
    --ion-color-app-outline-contrast-rgb: 0, 0, 0;
    --ion-color-app-outline-shade: #d9d6d2;
    --ion-color-app-outline-tint: #f8f4f1;

    /** Custom colors (text primary) **/
    --ion-color-snowy: #ffffff;
    --ion-color-snowy-rgb: 255, 255, 255;
    --ion-color-snowy-contrast: #000000;
    --ion-color-snowy-contrast-rgb: 0, 0, 0;
    --ion-color-snowy-shade: #e0e0e0;
    --ion-color-snowy-tint: #ffffff;

    --ion-color-jack: #000000;
    --ion-color-jack-rgb: 0, 0, 0;
    --ion-color-jack-contrast: #ffffff;
    --ion-color-jack-contrast-rgb: 255, 255, 255;
    --ion-color-jack-shade: #000000;
    --ion-color-jack-tint: #1a1a1a;

    /** Custom colors (text secondary) **/
    --ion-color-semi-jack: #212121;
    --ion-color-semi-jack-rgb: 33, 33, 33;
    --ion-color-semi-jack-contrast: #ffffff;
    --ion-color-semi-jack-contrast-rgb: 255, 255, 255;
    --ion-color-semi-jack-shade: #1d1d1d;
    --ion-color-semi-jack-tint: #373737;

    --ion-color-paragraph-color: #5c5c5c;
    --ion-color-paragraph-color-rgb: 92, 92, 92;
    --ion-color-paragraph-color-contrast: #ffffff;
    --ion-color-paragraph-color-contrast-rgb: 255, 255, 255;
    --ion-color-paragraph-color-shade: #515151;
    --ion-color-paragraph-color-tint: #6c6c6c;

    --ion-color-disabled-text: #bfbfbf;
    --ion-color-disabled-text-rgb: 191, 191, 191;
    --ion-color-disabled-text-contrast: #000000;
    --ion-color-disabled-text-contrast-rgb: 0, 0, 0;
    --ion-color-disabled-text-shade: #a8a8a8;
    --ion-color-disabled-text-tint: #c5c5c5;

    // not on Marija's UI kit colors
    --ion-color-barney: #e5fbfd;
    --ion-color-barney-rgb: 229, 251, 253;
    --ion-color-barney-contrast: #000000;
    --ion-color-barney-contrast-rgb: 0, 0, 0;
    --ion-color-barney-shade: #cadddf;
    --ion-color-barney-tint: #e8fbfd;

    --ion-color-very-light-grey: #e5e5e5;
    --ion-color-very-light-grey-rgb: 229, 229, 229;
    --ion-color-very-light-grey-contrast: #000000;
    --ion-color-very-light-grey-contrast-rgb: 0, 0, 0;
    --ion-color-very-light-grey-shade: #cacaca;
    --ion-color-very-light-grey-tint: #e8e8e8;

    /** Custom colors (used for disabled buttons) **/
    --ion-color-half-max: #f1afac;
    --ion-color-half-max-rgb: 241, 175, 172;
    --ion-color-half-max-contrast: #ffffff;
    --ion-color-half-max-contrast-rgb: 255, 255, 255;
    --ion-color-half-max-shade: #d49a97;
    --ion-color-half-max-tint: #f2b7b4;
}
