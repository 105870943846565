.no-padding {
    padding: 0 !important;
}

.no-margin-top {
    margin-top: 0 !important;
}

.no-margin-bottom {
    margin-bottom: 0 !important;
}

.no-margin {
    margin: 0 !important;
}

.unset-margin {
    margin: unset;
}

// show native elements like button as div
.as-div {
    background-color: transparent;
    font-size: initial;
    color: initial;
    margin-top: 0;
    margin-bottom: 0;
}

.hide-on-small-screen {
    @media (height <= 700px) {
        display: none;
    }
}
