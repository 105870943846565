@use '../mixins/media-query.mixin';

ion-textarea.reset {
    font-family: 'Helvetica Now Text', sans-serif;
    font-size: 17px;
    line-height: 26px;
    border: none;
    outline: none;

    &::placeholder {
        color: var(--ion-color-disabled-text);
    }

    &:not(.ignore-screen-based-font-scaling) {
        @include media-query.if-small-phone {
            font-size: 15px;
            line-height: 23px;
        }
    }
}
