.str-chat {
    --str-chat__primary-color: var(--ion-color-max);
    --str-chat__active-primary-color: var(--ion-color-max);
    --str-chat__surface-color: var(--ion-color-disabled-tint);
    --str-chat__secondary-surface-color: var(--ion-color-app-background);
    --str-chat__primary-surface-color: var(--ion-color-sophie);
    --str-chat__primary-surface-color-low-emphasis: var(--ion-color-barney);
    --str-chat__border-radius-circle: 50px;
    --str-chat__message-status-color: var(--ion-color-medium);
    --str-chat__message-bubble-box-shadow: 0 0 1px var(--ion-color-medium);
    --str-chat__border-radius-md: 12px;
    --str-chat__message-textarea-border-block-start: 1px solid var(--ion-color-bailey);
    --str-chat__message-textarea-border-block-end: 1px solid var(--ion-color-bailey);
    --str-chat__message-textarea-border-inline-start: 1px solid var(--ion-color-bailey);
    --str-chat__message-textarea-border-inline-end: 1px solid var(--ion-color-bailey);

    &__channel-preview-messenger--last-message {
        // Ensures that only the first part of the message shows in the message list
        white-space: nowrap !important;
    }

    &__emoji-display-fix {
        // This is so that str-chat * doesn't override the font-family in the chat
        font-family: initial;
    }

    &__edit-message-form &__edit-message-form-options &__edit-message-cancel {
        margin-right: 7px;
        text-transform: uppercase;
    }

    &__message--other &__message-text span {
        color: var(--ion-color-jack);
    }

    &__message--other &__message-bubble p {
        color: var(--ion-color-jack);
    }

    .str-chat__message--me .str-chat__message-text span {
        color: var(--ion-color-jack);
    }

    .str-chat__message--me .str-chat__message-bubble p {
        color: var(--ion-color-jack);
    }

    &__message-list-scroll {
        margin-top: 10px;
    }

    &__empty-channel svg path {
        display: none;
    }

    &__message &__attachment-list &__modal--open &__modal__inner {
        width: 90% !important;
        height: auto !important;
    }

    &__image-carousel &__image-carousel-image {
        min-width: auto !important;
        max-width: 100%;
        min-height: auto !important;
        max-height: 100%;
        object-fit: cover;
    }

    &__message &__message--error-message {
        color: var(--ion-color-oliver) !important;
    }

    &__message &__message-error-icon path {
        fill: var(--ion-color-sophie) !important;

        &#background {
            fill: var(--ion-color-oliver) !important;
        }
    }

    &__avatar &__avatar-image {
        background: none;
    }

    &__message-attachment-card--title {
        color: var(--ion-color-snowy) !important;
    }

    &__message-attachment-card--url {
        color: var(--ion-color-snowy) !important;
    }
}
