/** Primary **/
.max {
    background-color: var(--ion-color-max) !important;
    color: var(--ion-color-max-contrast) !important;
}

.harley {
    background-color: var(--ion-color-harley) !important;
    color: var(--ion-color-harley-contrast) !important;
}

.alvin {
    background-color: var(--ion-color-alvin) !important;
    color: var(--ion-color-alvin-contrast) !important;
}

.buddy {
    background-color: var(--ion-color-buddy) !important;
    color: var(--ion-color-buddy-contrast) !important;
}

/** Secondary light **/
.luna {
    background-color: var(--ion-color-luna) !important;
    color: var(--ion-color-luna-contrast) !important;
}

.lola {
    background-color: var(--ion-color-lola) !important;
    color: var(--ion-color-lola-contrast) !important;
}

.maggie {
    background-color: var(--ion-color-maggie) !important;
    color: var(--ion-color-maggie-contrast) !important;
}

.bailey {
    background-color: var(--ion-color-bailey) !important;
    color: var(--ion-color-bailey-contrast) !important;
}

/** Secondary bright **/
.bella {
    background-color: var(--ion-color-bella) !important;
    color: var(--ion-color-bella-contrast) !important;
}

.charly {
    background-color: var(--ion-color-charly) !important;
    color: var(--ion-color-charly-contrast) !important;
}

.sophie {
    background-color: var(--ion-color-sophie) !important;
    color: var(--ion-color-sophie-contrast) !important;
}

.oliver {
    background-color: var(--ion-color-oliver) !important;
    color: var(--ion-color-oliver-contrast) !important;
}

/** Additional App **/
.disabled-color {
    background-color: var(--ion-color-disabled) !important;
    color: var(--ion-color-disabled-contrast) !important;
}

.app-background {
    background-color: var(--ion-color-app-background) !important;
    color: var(--ion-color-app-background-contrast) !important;
}

.app-outline {
    // app outline
    background-color: var(--ion-color-app-outline) !important;
    color: var(--ion-color-app-outline-contrast) !important;
}

/** Text Primary **/
.jack {
    background-color: var(--ion-color-jack) !important;
    color: var(--ion-color-jack-contrast) !important;
}

.snowy {
    background-color: var(--ion-color-snowy) !important;
    color: var(--ion-color-snowy-contrast) !important;
}

/** Text Secondary **/
.semi-jack {
    background-color: var(--ion-color-semi-jack) !important;
    color: var(--ion-color-semi-jack-contrast) !important;
}

.paragraph-color {
    background-color: var(--ion-color-paragraph-color) !important;
    color: var(--ion-color-paragraph-color-contrast) !important;
}

.disabled-text-color {
    background-color: var(--ion-color-disabled-text) !important;
    color: var(--ion-color-disabled-text-contrast) !important;
}

/** Not in UI kit **/
.barney {
    background-color: var(--ion-color-barney) !important;
    color: var(--ion-color-barney-contrast) !important;
}
