@mixin box-shadow {
    box-shadow: 0 8px 18px -9px #ede4df !important;
}

ion-card {
    --border-radius: 24px;

    padding: 10px 0;
    border: 1px solid var(--ion-color-app-outline);
    border-radius: var(--border-radius);
    margin: 10px 0 0;

    @include box-shadow;

    --background: var(--ion-color-snowy);
}

.background-zig {
    background-image: url('/assets/images/zigzag/zig.png');
    background-position: center, center;
    background-repeat: no-repeat;
    background-size: cover;
}

.tile {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 20px 20px 15px;
}
