$button-solid-colors: ([ 'app-background-max', 'half-max'], [ 'max', 'half-max']);

$button-outline-colors: ('alvin', 'buddy', 'max', 'harley');

ion-button {
    // GL - creates a button in a certain color where you can set the background of the disabled button
    @each $color, $disabled-color in $button-solid-colors {
        &.ion-button-color-#{$color} {
            --background: var(--ion-color-#{$color});
            --background-activated: var(--ion-color-#{$color}-shade);
            --background-focused: var(--ion-color-#{$color}-shade);
            --background-hover: var(--ion-color-#{$color}-tint);

            --color: var(--ion-color-#{$color}-contrast);
            --color-activated: var(--ion-color-#{$color}-contrast);
            --color-focused: var(--ion-color-#{$color}-contrast);
            --color-hover: var(--ion-color-#{$color}-contrast);

            opacity: 1;

            &.button-disabled {
                --background: var(--ion-color-#{$disabled-color});

                --color: var(--ion-color-#{$disabled-color}-contrast);
            }
        }
    }

    @each $color in $button-outline-colors {
        &.ion-outline-button-color--#{$color} {
            --background: var(--ion-color-#{$color});
            --background-activated: var(--ion-color-snowy);
            --background-focused: var(--ion-color-#{$color});
            --background-hover: var(--ion-color-snowy);

            --border-style: solid;
            --border-width: 1px;
            --border-color-activated: none;

            --color: var(--ion-color-snowy);
            --color-activated: var(--ion-color-#{$color});
            --color-focused: var(--ion-color-snowy);
            --color-hover: var(--ion-color-#{$color});
        }
    }

    &.ion-button--transparent {
        --background: rgba(255, 255, 255, 0.2);
        --background-activated: rgba(255, 255, 255, 0.2);
        --background-focused: rgba(255, 255, 255, 0.2);
        --background-hover: rgba(255, 255, 255, 0.2);
    }
}
