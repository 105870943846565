.ai-chat {
    .str-chat {
        --str-chat__primary-surface-color: var(--ion-color-bailey);
        --str-chat__secondary-surface-color: var(--ion-color-snowy);

        * {
            white-space: normal !important;
        }

        &__message-input-angular-host {
            max-height: unset;
        }

        &__message-data.str-chat__message-simple-data.str-chat__message-metadata {
            display: none;
        }

        &__message--other &__message-text span {
            color: var(--ion-color-jack);
        }

        &__message--other &__message-bubble p {
            color: var(--ion-color-jack);
        }

        &__message--other .str-chat__message-inner {
            display: block;
            width: 100%;
            margin-inline-end: initial;
            padding-right: 10px;
        }
    }

    .stream-chat-ellipsis {
        span {
            @keyframes example {
                0%,
                50% {
                    bottom: 0;
                }
                25% {
                    bottom: 3px;
                }
            }

            position: relative;
            animation: example;
            animation-duration: 1s;
            animation-iteration-count: infinite;

            &:nth-child(2) {
                animation-delay: 0.1s;
            }

            &:nth-child(3) {
                animation-delay: 0.2s;
            }
        }
    }
}
