// Only for pages with the main tabs toolbar
.page-safe-margin {
    --padding-top: env(safe-area-inset-top) !important;
    --padding-bottom: calc(env(safe-area-inset-bottom) + 80px) !important;
}

.page-ion-content {
    --background: var(--ion-color-app-background);

    &--safe-area {
        --padding-bottom: env(safe-area-inset-bottom);
    }
}

.page-padding-top {
    padding-top: 40px;
}

.page-title {
    margin: 0 0 10px;
}

.page-content {
    @extend .page-padding-sides;

    display: flex;
    height: 100%;
    flex-direction: column;
    margin: 0 auto;
}

.page-footer {
    padding-bottom: calc(max(env(safe-area-inset-bottom), 25px));

    @extend .page-padding-sides;
}

.page-padding-sides {
    padding-right: 20px;
    padding-left: 20px;
}

.page-content-padding-top {
    padding-top: calc(max(env(safe-area-inset-top) + 10px, 25px));
}

ion-header.page-header {
    > ion-toolbar {
        --padding-top: 0;
        --padding-bottom: 0;
        --padding-start: 0;
        --padding-end: 0;
        --border-width: 0;
        --background: none;

        padding: 0;
    }
}

.page-padding-bottom {
    padding-bottom: calc(max(env(safe-area-inset-bottom), 25px));
}
