$custom-colors: (
    'max',
    'harley',
    'alvin',
    'buddy',
    'luna',
    'lola',
    'maggie',
    'bailey',
    'bella',
    'charly',
    'sophie',
    'oliver',
    'disabled',
    'app-background',
    'app-outline',
    'jack',
    'snowy',
    'semi-jack',
    'paragraph-color',
    'disabled-text',
    'barney'
);

@each $color in $custom-colors {
    .ion-color-#{$color} {
        --ion-color-base: var(--ion-color-#{$color}) !important;
        --ion-color-base-rgb: var(--ion-color-#{$color}-rgb) !important;
        --ion-color-contrast: var(--ion-color-#{$color}-contrast) !important;
        --ion-color-contrast-rgb: var(--ion-color-#{$color}-contrast-rgb) !important;
        --ion-color-shade: var(--ion-color-#{$color}-shade) !important;
        --ion-color-tint: var(--ion-color-#{$color}-tint) !important;
    }
}
