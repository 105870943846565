.contentful-embedded-image {
    display: block;
    max-width: 100%;
    max-height: 300px;
    object-fit: contain;
    margin-block-end: 1.5em;
    border-radius: 12px;
}

.contentful-rich-text {
    table {
        width: 100%;
        border-collapse: separate;
        border-spacing: 0;

        tbody tr th,
        tbody tr td {
            padding: 0 0 0 10px;
            vertical-align: top;
            text-align: left;
            border-right: 1px solid var(--ion-color-app-outline);
            border-bottom: 1px solid var(--ion-color-app-outline);

            &:first-child {
                border-left: 1px solid var(--ion-color-app-outline);
            }

            p {
                font-family: 'Helvetica Now Text', sans-serif;
                font-weight: 700;
                font-size: 17px;
            }
        }

        tbody tr th {
            background-color: var(--ion-color-app-outline);
            border-top: solid 1px var(--ion-color-app-outline);
        }

        tbody tr td p {
            font-weight: 400;
        }

        tbody th:first-of-type {
            border-top-left-radius: 24px;
        }

        tbody th:last-of-type {
            border-top-right-radius: 24px;
        }

        tbody tr:last-of-type td:first-of-type {
            border-bottom-left-radius: 24px;
        }

        tbody tr:last-of-type td:last-of-type {
            border-bottom-right-radius: 24px;
        }
    }
}
