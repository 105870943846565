.modal {
    &::part(content) {
        position: absolute;
        display: block;
    }

    ion-header {
        ion-toolbar {
            padding-top: 0 !important;
        }
    }
}

.modal-small {
    &::part(content) {
        top: 20%;
        height: 80% !important;
        border-radius: 30px 30px 0 0;

        @media (height <= 600px) {
            top: 10%;
            height: 90% !important;
        }
    }
}

.modal-center {
    &::part(content) {
        top: 35%;
        height: 65% !important;
        width: 90%;
        border-radius: 30px;

        @media (height <= 840px) and (height >= 700px) {
            top: 30%;
            height: 70% !important;
            width: 90%;
        }

        @media (height <= 700px) and (height >= 670px) {
            top: 20%;
            height: 80% !important;
            width: 90%;
        }

        @media (height <= 670px) {
            height: 95% !important;
            width: 90%;
        }
    }
}

.modal-fullscreen {
    &::part(content) {
        top: 0;
        width: 100% !important;
        height: 100% !important;
    }
}

.modal-large {
    &::part(content) {
        bottom: 0;
        height: calc(98% - var(--ion-safe-area-top, 0));
        border-radius: 30px 30px 0 0;
    }

    &::part(backdrop) {
        --backdrop-opacity: 0.5;
    }
}

.modal-window {
    &::part(content) {
        width: 88%;
        height: 85%;
        border-radius: 1.5rem;
        z-index: 12;
    }

    &::part(backdrop) {
        z-index: 11;
        --backdrop-opacity: 0.5;
    }
}

.modal-info-notification {
    &::part(content) {
        top: calc(env(height / 2));
        left: 6%;
        width: 88%;
        height: 334px !important;
        border-radius: 24px;
    }

    &::part(backdrop) {
        --backdrop-opacity: 0.5;
    }
}

// TODO: NBSon - evaluate if we can reduce the height of the modal, 70% is too much
// We should also put the cancel button outside of the 'buttons' div
.modal-skip-notification {
    &::part(content) {
        bottom: 0;
        height: 70%;
        max-height: 575px;
        border-radius: 30px 30px 0 0;
    }
}

.modal-training-reminder {
    &::part(content) {
        bottom: 0;
        height: 75%;
        max-height: 635px;
        border-radius: 30px 30px 0 0;
    }
}

.modal-pup-happiness {
    &::part(content) {
        bottom: 0;
        height: 90%;
        border-radius: 30px 30px 0 0;
    }
}

.lincoln-modal-background {
    --background: var(--ion-color-sophie);
}

.nourish-product-review-modal {
    &::part(content) {
        bottom: 0;
        height: 60%;
        border-radius: 30px 30px 0 0;

        transition: all 0.3s ease-in-out;
    }

    &::part(backdrop) {
        --backdrop-opacity: 0.5;
    }

    &--large {
        &::part(content) {
            height: calc(98% - var(--ion-safe-area-top, 0));
        }
    }
}
