@use '../mixins/media-query.mixin';

ion-datetime.zz-datetime {
    font-family:
        Cooper Md BT,
        serif;
    font-size: 18px;
    line-height: 22px;

    &:not(.ignore-screen-based-font-scaling) {
        @include media-query.if-small-phone {
            font-size: 14px;
            line-height: 17px;
        }
    }
}
