// course menu is defined in the ts file
ion-action-sheet.action-sheet {
    --background: none;
    --button-color: var(--ion-color-jack);

    button {
        background: rgba(white, 0.8) !important;
    }

    .action-sheet-cancel {
        font-weight: unset !important;
    }
}
