@font-face {
    font-family: 'PT Sans';
    src: url('/assets/fonts/PTSans-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'PT Serif';
    src: url('/assets/fonts/PTSerif-Bold.ttf') format('truetype');
}

@font-face {
    font-family: 'SF Pro text';
    src: url('/assets/fonts/SourceSansPro-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Cooper Md BT';
    src: url('/assets/fonts/CooperMdBT-Regular.ttf') format('truetype');
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url('/assets/fonts/HelveticaNowText-Regular.otf') format('opentype');
}
